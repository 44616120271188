// const trackers = []
import { post } from './requests'
import { apiTrackEvents } from '@/api'

import parseUserAgent from 'ua-parser-js'

class Tracker {
  /**
  @param {string} event_name
  @param {{[string]: any}} properties
	@returns {Promise<void>}
  */
  async track(event_name, properties = {}) {
    await this.retry(async () => {
      await post(
        apiTrackEvents.create.path(),
        this.body(event_name, properties),
      )
    })
  }

  /**
  @param {string} event_name
  @param {{[string]: any}} properties
  @returns {{[string]: any}}
  */
  body(event_name, properties = {}) {
    const userAgent = parseUserAgent()

    return {
      track_event: {
        event_name,
        properties: {
          ...properties,
          device_os: userAgent.os?.name,
          browser_name: userAgent.browser?.name,
          browser_version: userAgent.browser?.version,
        },
      },
    }
  }

  async retry(f, attempt = 0) {
    if (attempt >= 3) {
      console.warn('failed to send event completely')
      return
    }

    try {
      await f()
    } catch (err) {
      console.warn('failed to send event', err)
      setTimeout(async () => await this.retry(f, attempt + 1), 500)
    }
  }
}

export default new Tracker()
